import React, { Component } from "react"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import default_bg from "../img/default-bg.jpg"

const ImageWrapper = styled("header")`
    width: 100vw;
    height: 100vh;
    background-image: url(${props => (props.image ? props.image : default_bg)});
    background-size: cover;
    ${props => props.theme.bp.below.md} {
        display: flex;
        min-height: 100vh;
        height: auto;
        align-items: flex-end;
    }
`

export const HeaderContent = styled("div")`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 0;
    padding: 0 2rem;
    top: 0;
    width: 50vw;
    height: 100%;
    text-align: center;
    color: #fff;
    align-items: flex-start;
    background: ${props =>
        props.theme.transColor(props.theme.colors.primary, 0.7)};
    ${props => props.theme.bp.below.md} {
        padding: 1.5rem;
        position: relative;
        width: 100%;
        margin-top: calc(120px + 1.5rem);
        height: auto;
    }

    .handel &,
    .köpcenter & {
        background: ${props =>
            props.theme.transColor(props.theme.colors.retail, 0.7)};
    }
    .fastigheter & {
        background: ${props =>
            props.theme.transColor(props.theme.colors.properties, 0.7)};
    }
    .investeringar & {
        background: ${props =>
            props.theme.transColor(props.theme.colors.investments, 0.7)};
    }

    div {
        width: 80%;
        max-width: 600px;
        margin: 0 auto;
    }
    h1 {
        font-size: 70px;
        line-height: 1em;
        margin-bottom: 0.2em;
        width: 100%;
        ${props => props.theme.bp.below.sm} {
            font-size: 2rem;
        }
    }
    a {
        color: #fff;
        text-decoration: underline;
    }
    p,
    div {
        font-size: 19px;
        line-height: 1.5em;
        max-width: 80%;
        margin: 0 auto;
        ${props => props.theme.bp.below.md} {
            font-size: 1rem;
        }
    }
`

const Button = styled(Link)`
    color: #fff;
    border: 1px solid #fff;
    display: inline-block;
    margin: 2em auto;
    text-transform: uppercase;
    padding: 20px 35px;
    border-radius: 100px;
    font-weight: 400;
    letter-spacing: 3px;
    text-decoration: none !important;
    ${props => props.theme.bp.below.md} {
        margin-bottom: 0;
    }
`

class PageHeader extends Component {
    render() {
        const { pageData } = this.props
        console.log(pageData)
        return (
            <ImageWrapper image={pageData.image.url}>
                <HeaderContent>
                    {pageData.headTitle ? (
                        <h1>{pageData.headTitle}</h1>
                    ) : (
                        <h1>{pageData.title}</h1>
                    )}

                    <div
                        dangerouslySetInnerHTML={{ __html: pageData.excerpt }}
                    />
                    {pageData.headLink && (
                        <Button to={pageData.headLink}>
                            {pageData.headLinkText}
                        </Button>
                    )}
                </HeaderContent>
            </ImageWrapper>
        )
    }
}

export default PageHeader
