import React, { Fragment, useState } from "react"
import gql from "graphql-tag"
import styled from "@emotion/styled"
import { Query, Mutation } from "react-apollo"
import { MaxWidth, Flex } from "../UI/Containers"
import { SubmitButton } from "../UI/Button"
import { Input, TextArea } from "../UI/Input"
import { LoadingPage } from "../UI"

const FormContainer = styled("section")`
    background: ${props => props.theme.colors.primary};
    color: #fff;
    padding: ${props => props.theme.spacing.default};
    text-align: center;
`
const FormFlex = styled(Flex)`
    align-items: stretch;
    ${props => props.theme.bp.below.sm} {
        flex-wrap: wrap;
    }

    & > div {
        width: 50%;
        padding: 1rem;
        flex: 1;
        ${props => props.theme.bp.below.sm} {
            padding: 0 !important;
        }
        ${props => props.theme.bp.below.sm} {
            width: 100%;
            flex: unset;
        }
        &:last-child {
            padding: 2rem 1rem;
        }
        * {
            margin: 1rem;
            ${props => props.theme.bp.below.sm} {
                margin: 0;
                margin-bottom: 1rem;
            }
        }
        textarea {
            margin: 0;
        }
    }
`

const DepartmentRow = styled("div")`
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 66.6%;
    white-space: nowrap;
    a {
        text-decoration: underline;
    }
    ${props => props.theme.bp.below.lg} {
        width: 100%;
        padding: 0;
        flex-direction: column;
    }
`

const FlexAlign = styled("div")`
    display: flex;
    width: 50%;
    justify-content: ${props => props.justify};
    ${props => props.theme.bp.below.lg} {
        width: 100%;
        display: block;
    }
    div {
        min-width: 300px;
        span,
        a {
            display: block;
        }
    }
`

const PeopleWrapper = styled("div")`
    display: flex;
    text-align: left;
    flex-grow: 1;
    line-height: 1.5;
    width: 100%;
    margin-bottom: 2rem;
    h2 {
        font-size: 1rem;
        white-space: nowrap;
        font-weight: 100;
    }
    h3 {
        font-size: 0.9rem;
    }
    ${props => props.theme.bp.below.lg} {
        margin-bottom: 1rem;
        flex-direction: column;
        padding: 1rem;
    }
`

const DepartMentWrapper = styled("div")`
    display: flex;
    border-bottom: 1px solid black;
    margin-bottom: 2rem;
    &:last-of-type {
        border: none;
        margin-bottom: none;
    }
    h2,
    h3 {
        font-family: ${props => props.theme.fonts.body};
    }
    ${props => props.theme.bp.below.lg} {
        flex-direction: column;
        text-align: left;
    }
    & > h2 {
        font-size: 1rem;
        text-transform: uppercase;
        width: 33.3%;
        ${props => props.theme.bp.below.lg} {
            text-align: left;
            margin-left: 1rem;
            width: auto;
        }
    }
`

const StyledDepartments = styled("section")`
    padding: 3rem 0;
`

export const People = props => (
    <PeopleWrapper>
        <FlexAlign justify="center">
            <div>
                {props.title && <h3>{props.title}</h3>}
                <h2>{props.name}</h2>
            </div>
        </FlexAlign>
        <FlexAlign justify="flex-end">
            <div>
                {props.email && (
                    <a href={"mailto:" + props.email}>{props.email}</a>
                )}
                {props.phone && <span>Telefon: {props.phone}</span>}
                {props.mobile && <span>Mobil: {props.mobile}</span>}
            </div>
        </FlexAlign>
    </PeopleWrapper>
)

const Department = props => {
    return (
        <DepartMentWrapper>
            <h2>{props.name}</h2>
            <DepartmentRow>
                {props.peopleSet.map((p, i) => (
                    <People key={i} {...p} />
                ))}
            </DepartmentRow>
        </DepartMentWrapper>
    )
}

const DepartMentList = props => {
    return (
        <MaxWidth>
            {props.allDepartments.map((d, i) => {
                return <Department key={i} {...d} />
            })}
        </MaxWidth>
    )
}

const DepartmentsWrapper = () => {
    return (
        <StyledDepartments>
            <Query query={query}>
                {({ loading, err, data }) => {
                    if (loading) return <LoadingPage />
                    return <DepartMentList {...data} />
                }}
            </Query>
        </StyledDepartments>
    )
}

const query = gql`
    {
        allDepartments {
            name
            peopleSet {
                name
                email
                phone
                mobile
                title
                image(version: "contact") {
                    url
                }
            }
        }
    }
`

const Contact = props => {
    const page = props.page
    const [form, setForm] = useState({})
    const [sent, setSent] = useState(false)

    const updateData = e => {
        let formData = form
        formData[e.target.name] = e.target.value
        setForm(formData)
    }

    return (
        <Fragment>
            <FormContainer>
                <MaxWidth>
                    <div dangerouslySetInnerHTML={{ __html: page.content }} />
                    {sent ? (
                        <p>Ditt mail är skickat!</p>
                    ) : (
                        <form onChange={updateData}>
                            <FormFlex>
                                <div>
                                    <Input
                                        name="name"
                                        placeholder="Vad heter du?"
                                        required
                                    />
                                    <Input
                                        name="email"
                                        type="email"
                                        placeholder="Vad är din e-post?"
                                        required
                                    />
                                    <Input
                                        name="subject"
                                        placeholder="Vad gäller ditt ärende?"
                                        required
                                    />
                                </div>
                                <div>
                                    <TextArea
                                        name="message"
                                        placeholder="Berätta mer om vad vi kan hjälpa dig med."
                                        required
                                    />
                                </div>
                            </FormFlex>
                            <Mutation
                                mutation={FORM_MUTATION}
                                variables={{ emailData: form }}>
                                {(postMutation, { loading, error, data }) => (
                                    <Fragment>
                                        {error && (
                                            <p>
                                                Något gick fel, har du fyllt i
                                                samtliga fält?
                                            </p>
                                        )}
                                        <SubmitButton
                                            onClick={e => {
                                                e.preventDefault()
                                                postMutation()
                                            }}>
                                            {loading
                                                ? "Skickar..."
                                                : "Jag är klar, skicka!"}

                                            {data && setSent(data.sendEmail.ok)}
                                        </SubmitButton>
                                    </Fragment>
                                )}
                            </Mutation>
                        </form>
                    )}
                </MaxWidth>
            </FormContainer>
            <DepartmentsWrapper />
        </Fragment>
    )
}

const FORM_MUTATION = gql`
    mutation SendMail($emailData: EmailInput!) {
        sendEmail(emailData: $emailData) {
            ok
        }
    }
`

export default Contact
