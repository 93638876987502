import React, { Component } from "react"
import { Helmet } from "react-helmet"
import gql from "graphql-tag"
import styled from "@emotion/styled"
import { Query, graphql } from "react-apollo"
import { LoadingPage } from "../UI"
import { MaxWidth } from "../UI/Containers"
import PageHeader from "./PageHeader"
import Contact from "./Contact"
import Operations from "./Operations"
import { PlaceholderHeader } from "../UI/Placeholder"
import Iframe from "../UI/Iframe"

const PageContent = props => {
    const page = props.page
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{page.title}</title>
            </Helmet>
            {page.image.url ? (
                <PageHeader pageData={page} />
            ) : (
                <PlaceholderHeader {...page} />
            )}
            {(page.template === "CONTACT" && <Contact page={page} />) ||
                (page.template === "OPS" && <Operations page={page} />) || (
                    <React.Fragment>
                        <PageSection>
                            <MaxWidth
                                dangerouslySetInnerHTML={{
                                    __html: page.content
                                }}
                            />
                            {page.iframeUrl && (
                                <MaxWidth>
                                    <Iframe src={page.iframeUrl} />
                                </MaxWidth>
                            )}
                        </PageSection>
                        {page.boxSet &&
                            page.boxSet.map((box, index) => {
                                return (
                                    <PageSection key={index}>
                                        <MaxWidth>
                                            <h1>{box.title}</h1>
                                            {box.content && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: box.content
                                                    }}
                                                />
                                            )}
                                        </MaxWidth>
                                        {box.iframeUrl && (
                                            <MaxWidth>
                                                <Iframe
                                                    title={box.title}
                                                    src={box.iframeUrl}
                                                />
                                            </MaxWidth>
                                        )}
                                    </PageSection>
                                )
                            })}
                    </React.Fragment>
                )}
        </div>
    )
}

class Page extends Component {
    render() {
        const slug = this.props.match.params.slug
        return (
            <Query query={query} variables={{ slug }}>
                {({ loading, err, data }) => {
                    if (loading) return <LoadingPage />
                    return <PageContent {...data} />
                }}
            </Query>
        )
    }
}
const query = gql`
    query Page($slug: String) {
        page(slug: $slug) {
            headTitle
            excerpt
            headLink
            headLinkText
            title
            content
            template
            iframeUrl
            image {
                url
            }
            boxSet {
                title
                content
                iframeUrl
            }
        }
    }
`

export const PageSection = styled("section")`
    line-height: 1.5em;
    &:nth-child(even) {
        background: ${props => props.theme.colors.accent};
    }
    padding: ${props => props.theme.spacing.default};
    ${props => props.theme.bp.below.sm} {
        padding: ${props => props.theme.spacing.small};
    }
    > div {
        padding: 0px 150px;
        ${props => props.theme.bp.below.sm} {
            padding: 0px;
    }
    a{
        text-decoration:underline;
    }
    table{
        width:100%;
        border-collapse:collapse;
        th,td{
            margin:0;
            border:0;
            padding:.5rem;
            text-align:left;
        }
        tr:nth-of-type(even){
            background:white;
        }
        tr:nth-of-type(odd){
            background:#f1f1f1;
        }
        th{
            background: ${props => props.theme.colors.secondary};
            color:white;
        }
    }

    table.invoice-list{
        ${props => props.theme.bp.below.sm} {
            display:block;
            thead{
                display:none;
            }
            tbody{
                display:block;
            }
            tr{
                background:white;
                margin-bottom:1.5rem;
                display:flex;
                flex-direction:column;
                font-size:13px;
                td:nth-of-type(1){
                    background: ${props => props.theme.colors.secondary};
                    color:white;
                    font-size:16px;
                    font-weight:700;
                }
                td:nth-of-type(2),
                td:nth-of-type(3),
                td:nth-of-type(4){
                    display:flex;
                    &::before{
                        font-weight:700;
                        width:30%;
                    }
                }
                td:nth-of-type(2)::before{
                        content:"Adress";
                    
                }
                td:nth-of-type(3)::before{
                        content:"Postnr + Ort";
                }
                td:nth-of-type(4)::before{
                        content:"Mail för PDF";
                }
            }
        }
    }
`

export default graphql(query)(Page)
