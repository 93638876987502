import React, { Component } from "react"
import gql from "graphql-tag"
import { Query, graphql } from "react-apollo"
import { LoadingPage } from "../UI"
import Button from "../UI/Button"
import { Link } from "react-router-dom"
import styled from "@emotion/styled"

const Businesses = styled("section")`
    display: flex;
    flex-wrap: wrap;
    background: #fff;
`

const BusinessButton = styled(Button)`
    color: #fff;
    border-color: #fff;
    right: ${props => props.theme.spacing.default};
    bottom: ${props => props.theme.spacing.default};
    position: absolute !important;
    margin: 0;
    font-size: 12px;
    ${props => props.theme.bp.below.sm} {
        font-size: 10px;
        padding: 12px 22px;
    }
    ${props => props.theme.bp.below.md} {
        right: ${props => props.theme.spacing.small};
        bottom: ${props => props.theme.spacing.small};
    }
`

const Post = styled("article")`
    width: 50vw;
    min-height: 30vw;
    padding: ${props => props.theme.spacing.default};
    text-align: left;
    color: #fff;
    position: relative;
    ${props => props.theme.bp.above.xl} {
        width: 33.3333vw;
        padding-bottom: 10rem;
    }
    ${props => props.theme.bp.below.md} {
        width: 100vw;
        padding: ${props => props.theme.spacing.small};
        padding-bottom: 10rem;
    }

    &:after,
    &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        content: "";
        z-index: 1;
        background-image: url(${props => props.background});
        background-size: cover;
        background-position: center;
    }
    &:after {
        z-index: 3;
    }
    &:before {
        filter: saturate(33%);
    }
    &.Handel,
    &.Köpcenter {
        &:after {
            background: ${props =>
                props.theme.transColor(props.theme.colors.retail, 0.75)};
        }
    }
    &.Fastigheter {
        &:after {
            background: ${props =>
                props.theme.transColor(props.theme.colors.properties, 0.75)};
        }
    }
    &.Investeringar {
        &:after {
            background: ${props =>
                props.theme.transColor(props.theme.colors.investments, 0.75)};
        }
    }

    span {
        background: ${props => props.theme.colors.primary};
        padding: 10px 15px;
        border-radius: 50px;
        font-size: 10px;
        z-index: 4;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 3px;
        &.Fastigheter {
            background: ${props => props.theme.colors.properties};
        }
        &.Handel,
        &.Köpcenter {
            background: ${props => props.theme.colors.retail};
        }
        &.Investeringar {
            background: ${props => props.theme.colors.investments};
        }
    }
    p {
        font-size: 17px;
        line-height: 1.5em;
    }
    h3 {
        z-index: 4;
        position: relative;
        margin: 1em 0 0.2em;
        font-size: 40px;
        a {
            color: #fff;
        }
    }
    a {
        z-index: 4;
        position: relative;
        color: #fff;
    }

    div {
        position: relative;
        z-index: 4;
        line-height: 1.5em;
    }
`

export const BusinessLink = styled("a")`
    position: ${props => (props.static ? "static" : "absolute")}!important;
    left: ${props => props.theme.spacing.default};
    bottom: ${props => props.theme.spacing.default};
    text-transform: uppercase;
    padding-bottom: 14px;
    border-bottom: 1px solid ${props => (props.inline ? "#000" : "#fff")};
    letter-spacing: 3px;
    font-size: 12px;
    ${props => props.theme.bp.below.md} {
        left: ${props => props.theme.spacing.small};
        bottom: ${props => props.theme.spacing.small};
    }
`

export const SingleBusiness = ({ post }) => {
    let content =
        post.content.length > 350
            ? post.content
                  .split(" ")
                  .slice(0, 30)
                  .join(" ") + "..."
            : post.content

    return (
        <Post background={post.image.url} className={post.category}>
            <span className={post.category}>{post.category}</span>
            <h3>
                <Link to={"/business/" + post.slug}>{post.title}</Link>
            </h3>
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {post.website !== null && (
                <BusinessLink
                    rel="noopener noreferrer"
                    target="_blank"
                    href={post.website}>
                    {post.website
                        .replace("http://", "")
                        .replace("https://", "")
                        .replace("www.", "")}
                </BusinessLink>
            )}

            <BusinessButton to={"/business/" + post.slug}>
                Läs mer
            </BusinessButton>
        </Post>
    )
}

const BusinessListContent = props => {
    const posts = props.allProperties
        ? props.allProperties.edges
        : props.startpageProperties.edges
    const filters = props.filters
    return (
        <Businesses>
            {posts.map((p, i) => {
                let post = p.node
                if (
                    filters &&
                    filters.length > 0 &&
                    filters.indexOf(post.category) < 0
                ) {
                    return ""
                }
                return <SingleBusiness post={post} key={i} />
            })}
        </Businesses>
    )
}

class BusinessList extends Component {
    render() {
        const { first, filters } = this.props
        return (
            <Query query={first ? start_query : query} variables={{ first }}>
                {({ loading, err, data }) => {
                    if (loading) {
                        return <LoadingPage />
                    }
                    return <BusinessListContent filters={filters} {...data} />
                }}
            </Query>
        )
    }
}

const query = gql`
    query Properties($first: Int) {
        allProperties(first: $first, props: true) {
            edges {
                node {
                    category
                    title
                    slug
                    content
                    website
                    image(version: "business_box") {
                        url
                    }
                }
            }
        }
    }
`

const start_query = gql`
    query startPageProps($first: Int) {
        startpageProperties(first: $first) {
            edges {
                node {
                    category
                    title
                    slug
                    content
                    website
                    image(version: "business_box") {
                        url
                    }
                }
            }
        }
    }
`

export default graphql(query)(BusinessList)
