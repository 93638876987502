import React, { Component, Fragment } from "react"
import { Helmet } from "react-helmet"
import gql from "graphql-tag"
import styled from "@emotion/styled"
import { Query, graphql } from "react-apollo"
import { LoadingPage } from "../UI"
import { FlexMaxWidth } from "../UI/Containers"
import PageHeader from "../Page/PageHeader"
import { BusinessLink } from "../Business/BusinessList"

const PageSection = styled("section")`
    line-height: 1.5em;
    &:nth-child(even) {
        background: ${props => props.theme.colors.accent};
    }
    padding: ${props => props.theme.spacing.default};
    ${props => props.theme.bp.below.sm} {
        padding: ${props => props.theme.spacing.small};
    }
    > div {
        padding: 0px 150px;
        ${props => props.theme.bp.below.md} {
            padding: 0px 50px;
        }
        ${props => props.theme.bp.below.sm} {
            padding: 0px;
        }
    }
`

const Sidebar = styled("aside")`
    max-width: 16rem;
    width: 100%;
    margin-left: 2rem;
    flex-grow: 1;
    ${props => props.theme.bp.below.sm} {
        max-width: 100%;
        margin-left: 0;
    }
    h2 {
        margin-bottom: 0.5rem;
    }
    a,
    span {
        display: block;
    }
    img {
        margin: 1.5rem 0;
        max-width: 100%;
        height: auto;
        max-height: 120px;
        max-width: 120px;
    }
`

const ContactPerson = props => (
    <Fragment>
        <h2>{props.name}</h2>

        {props.email && <a href={"mailto:" + props.email}>{props.email}</a>}
        {props.phone && <span>Telefon: {props.phone}</span>}
        {props.mobile && <span>Mobil: {props.mobile}</span>}
    </Fragment>
)

const SideCol = ({ property }) => (
    <Sidebar>
        {property.contact && <ContactPerson {...property.contact} />}
        {property.logo.url && (
            <img src={property.logo.url} alt={property.title} />
        )}
        {property.website !== null && (
            <BusinessLink
                static="1"
                inline="1"
                rel="noopener noreferrer"
                target="_blank"
                href={property.website}>
                {property.website
                    .replace("http://", "")
                    .replace("https://", "")
                    .replace("www.", "")}
            </BusinessLink>
        )}
    </Sidebar>
)

class BodyClassSetter extends Component {
    componentWillMount() {
        this.props.pageData.category
            ? (document.getElementsByTagName(
                  "body"
              )[0].className = this.props.pageData.category.toLowerCase())
            : (document.getElementsByTagName("body")[0].className = "default")
    }
    componentWillUnmount() {
        document.getElementsByTagName("body")[0].className = "default"
    }

    render() {
        return null
    }
}

const PageContent = props => {
    const property = props.property
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{property.title}</title>
            </Helmet>
            {property.image.url ? <PageHeader pageData={property} /> : null}
            <React.Fragment>
                <PageSection>
                    <FlexMaxWidth>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: property.content
                            }}
                        />

                        <SideCol property={property} />
                    </FlexMaxWidth>
                </PageSection>
                <BodyClassSetter pageData={property} />
            </React.Fragment>
        </div>
    )
}

class BusinessPage extends Component {
    render() {
        const slug = this.props.match.params.slug
        return (
            <Query query={query} variables={{ slug }}>
                {({ loading, err, data }) => {
                    if (loading) return <LoadingPage />
                    return <PageContent {...data} />
                }}
            </Query>
        )
    }
}
const query = gql`
    query Property($slug: String) {
        property(slug: $slug) {
            title
            content
            excerpt
            website
            category
            image {
                url
            }
            logo {
                url
            }
            images {
                image {
                    url
                }
            }
            contact {
                name
                email
                phone
                mobile
            }
        }
    }
`
export default graphql(query)(BusinessPage)
