import React from "react"
import { ThemeProvider } from "emotion-theming"
import { Global } from "@emotion/core"

const colors = {
    primary: "#004a60",
    primaryDark: "#173d51",
    accent: "#f7f7f7",
    secondary: "#2b2a2b",
    properties: "#00615a",
    retail: "#1d495f",
    investments: "#cc4148",
    black: "#2b2a2b"
}

const fonts = {
    headings: "'Lora', serif",
    body: "'Roboto', sans-serif"
}

export const sizes = {
    maxWidth: "1200px",
    sm: 420,
    md: 768,
    lg: 1024,
    desk: 1200,
    xl: 1400
}
const spacing = {
    default: "3rem",
    small: "1.5rem"
}

const bp = {
    above: {
        sm: `@media (min-width: ${sizes.sm}px)`,
        md: `@media (min-width: ${sizes.md}px)`,
        lg: `@media (min-width: ${sizes.lg}px)`,
        xl: `@media (min-width: ${sizes.xl}px)`
    },
    below: {
        sm: `@media (max-width: ${sizes.sm}px)`,
        md: `@media (max-width: ${sizes.md}px)`,
        lg: `@media (max-width: ${sizes.lg}px)`,
        xl: `@media (max-width: ${sizes.xl}px)`
    }
}

const transColor = (hex, opacity) => {
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("")
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = "0x" + c.join("")
        return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            "," +
            opacity +
            ")"
        )
    }
}

const theme = {
    colors,
    fonts,
    sizes,
    transColor,
    spacing,
    bp
}
/*
injectGlobal`
  html, body {
    font-family: ${fonts.body};
		font-weight:300;
		color:${colors.black};
  }
  body {
		background: #f8f8f8;
		color:${colors.black};
  }
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  strong, b {
    font-weight: bold;
  }
  h1,h2,h3,h4,h5,h6 {
			font-family:${fonts.headings};
			color:${colors.black};
  }
`
*/

const Theme = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <Global
                styles={{
                    html: {
                        fontFamily: fonts.body,
                        fontWeight: 300
                    },
                    "*": {
                        margin: 0,
                        boxSizing: "border-box"
                    },
                    body: {
                        background: "#FFF",
                        margin: 0,
                        fontFamily: fonts.body,
                        fontWeight: 300
                    },
                    strong: {
                        fontWeight: "bold"
                    },
                    "h1,h2,h3,h4,h5,h6": {
                        fontFamily: fonts.headings,
                        fontWeight: 400
                    },
                    h1: {
                        fontSize: "40px",
                        marginBottom: ".5em",
                        lineHeight: "1.2"
                    },
                    a: {
                        textDecoration: "none",
                        color: "#000"
                    },
                    p: {
                        marginBottom: "1em",
                        fontFamily: fonts.body,
                        fontWeight: 300
                    },
                    iframe: {
                        border: "none",
                        width: "100%"
                    }
                }}
            />
            {children}
        </ThemeProvider>
    )
}
export default Theme
