import React from "react"
import styled from "@emotion/styled"
import BusinessList from "../Business/BusinessList"
import { withRouter } from "react-router-dom"

const OperationsToolbar = styled("div")`
    display: flex;
    padding: 2rem 3rem;
    flex-wrap: wrap;
    ${props => props.theme.bp.below.lg} {
        padding: 1rem;
    }
    span {
        cursor: pointer;
        background: ${props => props.theme.colors.primary};
        padding: 10px 15px;
        border-radius: 50px;
        font-size: 10px;
        z-index: 4;
        position: relative;
        text-transform: uppercase;
        letter-spacing: 3px;
        color: #fff;
        margin-right: 0.5rem;
        border: 1px solid;
        transition: 0.3s ease-in-out;
        ${props => props.theme.bp.below.md} {
            margin: 0.5rem;
        }
        &.Fastigheter {
            background: ${props => props.theme.colors.properties};
            border-color: ${props => props.theme.colors.properties};
            color: ${props => props.theme.colors.properties};
        }
        &.Handel,
        &.Köpcenter {
            background: ${props => props.theme.colors.retail};
            border-color: ${props => props.theme.colors.retail};
            color: ${props => props.theme.colors.retail};
        }
        &.Investeringar {
            background: ${props => props.theme.colors.investments};
            border-color: ${props => props.theme.colors.investments};
            color: ${props => props.theme.colors.investments};
        }
        &.active {
            background: transparent;
        }
        &.inActive {
            color: #fff;
        }
    }
`

const FilterButton = props => {
    const isActive = props.filters.includes(props.name)
        ? " active"
        : " inActive"

    return (
        <span
            className={props.name + isActive}
            onClick={() => props.applyFilters(props.name)}>
            {props.name}
        </span>
    )
}

class Operations extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filters: []
        }
    }
    applyFilters = category => {
        var filters = this.state.filters
        if (this.state.filters.indexOf(category) > -1) {
            // TODO: Vad är denna till för?
            //var index = this.state.filters.indexOf(category)
            filters.splice(this.state.filters.indexOf(category), 1)
        } else {
            filters.push(category)
        }
        this.setState({ filters: filters }, () => {
            const url =
                this.state.filters.length > 0
                    ? `?filters=${this.state.filters.join(",")}`
                    : "?"
            this.props.history.push(url)
        })
    }

    handleUrl = () => {
        let url = new URL(window.location.href)
        let f = url.searchParams.get("filters")
        this.setState({ filters: f ? f.split(",") : [] })
    }

    componentDidMount() {
        this.handleUrl()
    }

    componentDidUpdate() {
        window.onpopstate = e => {
            this.handleUrl()
        }
    }

    render() {
        return (
            <React.Fragment>
                <OperationsToolbar>
                    <FilterButton
                        filters={this.state.filters}
                        applyFilters={this.applyFilters}
                        name="Handel"
                    />
                    <FilterButton
                        filters={this.state.filters}
                        applyFilters={this.applyFilters}
                        name="Fastigheter"
                    />
                    <FilterButton
                        filters={this.state.filters}
                        applyFilters={this.applyFilters}
                        name="Investeringar"
                    />
                    <FilterButton
                        filters={this.state.filters}
                        applyFilters={this.applyFilters}
                        name="Köpcenter"
                    />
                </OperationsToolbar>
                <BusinessList lasf={10} filters={this.state.filters} />
            </React.Fragment>
        )
    }
}
export default withRouter(Operations)
