import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { ApolloProvider } from "react-apollo"
import ApolloClient from "apollo-boost"
import Theme from "./Theme"
import { Page, StartPage, SearchPage } from "./Page"
import BusinessPage from "./Business/BusinessPage"
import ScrollToTop from "./UI/Helpers"
import { NewsList, NewsPost } from "./News"
import { Footer } from "./Footer"
import { Header } from "./Header"
import "./App.css"

const graph_uri =
    process.env.NODE_ENV === "development"
        ? "http://localhost:3020/graphql"
        : "https://api.orvelin.se/graphql"

const client = new ApolloClient({
    uri: graph_uri
})

const NoMatch = ({ location }) => (
    <div>
        <h3>Hoppsan...</h3>
    </div>
)

class App extends Component {
    render() {
        return (
            <ApolloProvider client={client}>
                <Router>
                    <ScrollToTop>
                        <Theme>
                            <Header />
                            <main>
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        component={StartPage}
                                    />
                                    <Route
                                        exact
                                        path="/nyheter"
                                        component={NewsList}
                                    />
                                    <Route
                                        path="/nyheter/:slug"
                                        component={NewsPost}
                                    />
                                    <Route
                                        path="/business/:slug"
                                        component={BusinessPage}
                                    />
                                    <Route
                                        path="/sokresultat/:searchTerm"
                                        component={SearchPage}
                                    />
                                    <Route path="/:slug" component={Page} />

                                    <Route component={NoMatch} />
                                </Switch>
                            </main>
                            <Footer />
                        </Theme>
                    </ScrollToTop>
                </Router>
            </ApolloProvider>
        )
    }
}

export default App
